<!--
 * @Author: zhuchaowei
 * @Date: 2024-05-24 11:50:44
 * @email: 952698119@qq.com
 * @LastEditors: zhuchaowei
 * @LastEditTime: 2024-05-28 09:40:25
 * @Description: 
-->
<template>
  <div>
    <el-dialog
      title="付款"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
      destroy-on-close
      width="30%"
    >
      <div class="flex-center">
        <div><i class="el-icon-warning"></i></div>
        <div class="tips">是否确认绩效已付款，付款后无法改变付款状态</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
        <el-button @click="submitForm" type="primary">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "PayDialog",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: false,
      dataForm: {},
    };
  },
  watch: {
    show(n) {
      this.dialogVisible = n;
    },
    dialogVisible(n) {
      if (!n) {
        this.$emit("update:show", false);
      }
    },
  },
  methods: {
    clearForm() {
      let keys = Object.keys(this.dataForm);
      keys.forEach((key) => {
        if (this.dataForm[key] instanceof Array) {
          this.dataForm[key] = [];
        } else if (this.dataForm[key] instanceof Object) {
          Object.keys(this.dataForm[key]).forEach((key2) => {
            this.dataForm[key][key2] = "";
          });
        } else {
          this.dataForm[key] = "";
        }
      });
      //   for (const key in this.distributionTables) {
      //     this.distributionTables[key] = [];
      //   }
    },
    handleClose(done) {
      this.clearForm();
      done();
    },
    submitForm() {
      this.$emit("submit", this.dataForm);
      this.clearForm();
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  border-radius: 8px;
}
::v-deep .el-dialog__body {
  padding: 24px 32px;
  border-top: 1px solid rgba(120, 120, 120, 0.3);
  border-bottom: 1px solid rgba(120, 120, 120, 0.3);
}
.el-icon-warning {
  color: #e6a23c;
  font-size: 20px;
}
.tips {
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.flex-center {
  display: flex;
  //justify-content: center;
  align-items: center;
}
</style>
