var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kpi-dr"},[_c('el-dialog',{attrs:{"title":"收款","visible":_vm.dialogVisible,"before-close":_vm.handleClose,"close-on-click-modal":false,"destroy-on-close":"","width":"464px"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',[_c('TextInput',{attrs:{"config":{
          label: '收款金额',
          type: 'input',
          isNum: true,
          placeholder: '请输入收款金额',
        }},model:{value:(_vm.dataForm.detailAmount),callback:function ($$v) {_vm.$set(_vm.dataForm, "detailAmount", $$v)},expression:"dataForm.detailAmount"}}),_c('TextInput',{attrs:{"config":{
          label: '收款时间',
          type: 'slot',
          placeholder: '请选择收款时间',
        }}},[_c('el-date-picker',{attrs:{"value-format":"yyyy-MM-dd HH:mm:ss","type":"datetime","placeholder":"选择收款时间"},model:{value:(_vm.dataForm.detailDate),callback:function ($$v) {_vm.$set(_vm.dataForm, "detailDate", $$v)},expression:"dataForm.detailDate"}})],1),_c('TextInput',{attrs:{"config":{
          label: '备注',
          type: 'textarea',
          placeholder: '请输入备注',
        }},model:{value:(_vm.dataForm.remark),callback:function ($$v) {_vm.$set(_vm.dataForm, "remark", $$v)},expression:"dataForm.remark"}})],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("关闭")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submitForm}},[_vm._v("确 定")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }