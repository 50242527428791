<!--
 * @Author: zhuchaowei
 * @Date: 2024-05-24 11:50:44
 * @email: 952698119@qq.com
 * @LastEditors: zhuchaowei
 * @LastEditTime: 2024-05-28 09:24:03
 * @Description: 
-->
<template>
  <div class="kpi-dr">
    <el-dialog
      title="收款"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
      destroy-on-close
      width="464px"
    >
      <div>
        <TextInput
          :config="{
            label: '收款金额',
            type: 'input',
            isNum: true,
            placeholder: '请输入收款金额',
          }"
          v-model="dataForm.detailAmount"
        ></TextInput>
        <TextInput
          :config="{
            label: '收款时间',
            type: 'slot',
            placeholder: '请选择收款时间',
          }"
        >
        <el-date-picker
          v-model="dataForm.detailDate" value-format="yyyy-MM-dd HH:mm:ss"
          type="datetime"
          placeholder="选择收款时间">
        </el-date-picker>
        </TextInput>
        <TextInput
          :config="{
            label: '备注',
            type: 'textarea',
            placeholder: '请输入备注',
          }"
          v-model="dataForm.remark"
        ></TextInput>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
        <el-button @click="submitForm" type="primary">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import TextInput from "@/components/SimpleTable/TextInput.vue";
export default {
  name: "CollectionDialog",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TextInput,
  },
  data() {
    return {
      dialogVisible: false,
      dataForm: {
        // amount: "",
        // recordId: "",
        detailAmount: '',
        detailDate: '',
        remark: '',
      },
    };
  },
  watch: {
    show(n) {
      this.dialogVisible = n;
    },
    dialogVisible(n) {
      if (!n) {
        this.$emit("update:show", false);
      }
    },
  },
  methods: {
    clearForm() {
      let keys = Object.keys(this.dataForm);
      keys.forEach((key) => {
        if (this.dataForm[key] instanceof Array) {
          this.dataForm[key] = [];
        } else if (this.dataForm[key] instanceof Object) {
          Object.keys(this.dataForm[key]).forEach((key2) => {
            this.dataForm[key][key2] = "";
          });
        } else {
          this.dataForm[key] = "";
        }
      });
      //   for (const key in this.distributionTables) {
      //     this.distributionTables[key] = [];
      //   }
    },
    handleClose(done) {
      this.clearForm();
      done();
    },
    submitForm() {
      if (!this.dataForm.detailAmount) {
        this.$message.error('请输入收款金额');
        return false;
      }
      this.$emit("submit", this.dataForm);
      this.clearForm();
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>

.kpi-dr {
  ::v-deep .el-dialog {
    border-radius: 8px;
    .el-dialog__header {
      position: relative;
      padding: 0;
      padding-left: 24px;
      height: 40px;
      line-height: 40px;
      // background: #4278C9;
      border-radius: 8px 8px 0px 0px;
      .el-dialog__title {
        font-weight: bold;
        font-size: 16px;
        color: #333333;
      }
      .el-dialog__headerbtn {
        top: 50%;
        transform: translateY(-50%);
        .el-dialog__close {
          font-weight: bold;
          font-size: 18px;
          color: #333333;
        }
      }
    }
  }
  ::v-deep .el-dialog__body {
    padding: 16px;
    border-top: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
  }
  }
</style>
