<template>
  <el-dialog
    :visible.sync="dialogVisible"
    @close="clearClose"
    custom-class="add-regulation-dialog"
    destroy-on-close
    title="调整手续费"
    width="640px"
  >
    <div slot="footer">
      <el-button @click="clearClose">关 闭</el-button>
      <el-button @click="confirm" type="primary">确 认</el-button>
    </div>
    <el-form :model="form" :rules="rules" ref="form">
      <TextInput
        :config="{
          label: '税后手续费率',
          type: 'input',
          isNum: true,
          width: '500px',
        }"
        labelPosition="left"
        v-model="form.afterTaxRate"
        ><span slot="inputAppend">%</span></TextInput
      >

      <TextInput
        :config="{
          label: '个人佣金费率',
          type: 'input',
          isNum: true,
          width: '500px',
        }"
        labelPosition="left"
        v-model="form.commissionFee"
        ><span slot="inputAppend">%</span></TextInput
      >

      <TextInput
        :config="{
          label: '公估服务费率',
          type: 'input',
          isNum: true,
          width: '500px',
        }"
        labelPosition="left"
        v-model="form.assessmentServiceFee"
        ><span slot="inputAppend">%</span></TextInput
      >

      <TextInput
        :config="{
          label: '公司保留费率',
          type: 'input',
          isNum: true,
          width: '500px',
        }"
        labelPosition="left"
        v-model="form.companyReserveFee"
        ><span slot="inputAppend">%</span></TextInput
      >

      <TextInput
        :config="{
          label: '部门保留费率',
          type: 'input',
          isNum: true,
          width: '500px',
        }"
        labelPosition="left"
        v-model="form.deptReserveFee"
        ><span slot="inputAppend">%</span></TextInput
      >
      <TextInput
        :config="{
          label: '业务推荐费率',
          type: 'input',
          isNum: true,
          width: '500px',
        }"
        labelPosition="left"
        v-model="form.serviceReferralFee"
        ><span slot="inputAppend">%</span></TextInput
      >
      <div>
        <el-table :data="form.recordSectionList" border>
          <el-table-column label="ID" type="index"></el-table-column>
          <el-table-column label="团队长名称" prop="userName"></el-table-column>
          <el-table-column label="团队长费率" prop="performanceRate">
            <template slot-scope="{ row }"
              ><TextInput
                :config="{
                  type: 'input',
                  isNum: true,
                  width: '100%',
                }"
                labelPosition="left"
                v-model="row.performanceRate"
                ><span slot="inputAppend">%</span></TextInput
              ></template
            >
          </el-table-column>
        </el-table>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { inputNum } from "@/views/routerProjectManagement/components/ProjectList/components/utils.js";
import TextInput from "@/components/SimpleTable/TextInput.vue";
// import { OSSDirectPass } from "@/api/oss.js";
// import {
//   performanceInsuredPage,
//   performancePlanPage,
//   redactRule,
//   adjustPerformance,
// } from "@/api/policy";
import { adjustKpiRuleFee } from "@/api/kpi.js";
export default {
  name: "AddRegulationDialog",
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    editDetail: {
      type: [Object, Array],
      default: null,
    },
    // id: {
    //   type: [String, Number],
    //   default: "",
    // },
  },
  components: { TextInput },
  data() {
    return {
      dialogVisible: false,
      topLevelId: 0,
      customerList: [],
      programmeList: [],
      form: {
        agentKick: "",
        brokerage: "",
        otherBrokerage: "",
        ///
        afterTaxAmount: "",
        afterTaxRate: "",
        agentId: "",
        agentName: "",
        assessmentServiceAmount: "",
        assessmentServiceFee: "",
        bailiffRate: "",
        cardNoColl: "",
        category: "",
        collectionStatus: "",
        commissionAmount: "",
        commissionFee: "",
        companyReserveAmount: "",
        companyReserveFee: "",
        createTime: "",
        deptReserveAmount: "",
        deptReserveFee: "",
        handlingCharge: "",
        id: "",
        insertType: "",
        insuranceOfDate: "",
        officialReceiptsAmount: "",
        otherRate: "",
        ourCompanyRate: "",
        paymentStatus: "",
        performanceTypeName: "",
        policyNo: "",
        premium: "",
        primaryInsuranceBranchName: "",
        primaryInsuranceCompanyName: "",
        projectId: "",
        projectName: "",
        projectPerformanceType: "",
        recordSectionList: [
          {
            createTime: "",
            id: "",
            performanceAmount: "",
            performanceRate: "",
            performanceRecordId: "",
            updateTime: "",
            userId: "",
            userName: "",
          },
        ],
        salesmanDeptId: "",
        salesmanDeptName: "",
        salesmanId: "",
        salesmanName: "",
        schemeName: "",
        // schemeSectionList: [
        //   {
        //     dataType: 0,
        //     id: 0,
        //     isDeleted: true,
        //     performanceRate: 0,
        //     ruleSchemeId: 0,
        //     userId: 0,
        //     userName: "",
        //   },
        // ],
        serialNo: "",
        serviceReferralAmount: "",
        serviceReferralFee: "",
        teamLeaderAmount: "",
        teamLeaderFee: "",
        updateTime: "",
      },
      rules: {
        ruleName: [
          { required: true, message: "请输入规则名称", trigger: "change" },
        ],
        customerId: [
          { required: true, message: "请选择绑定客户", trigger: "change" },
        ],
        programmeName: [
          { required: true, message: "请选择方案名称", trigger: "change" },
        ],
        agentKick: [
          { required: true, message: "请输入代理商返点", trigger: "change" },
        ],
        brokerage: [
          { required: true, message: "请选择佣金绩效费率", trigger: "change" },
        ],
        otherBrokerage: [
          { required: true, message: "请输入其他绩效费率", trigger: "change" },
        ],
      },
      satisfactionList: [
        {
          key: 5,
          value: "非常满意",
        },
        {
          key: 4,
          value: "满意",
        },
        {
          key: 3,
          value: "一般",
        },
        {
          key: 2,
          value: "不满意",
        },
        {
          key: 1,
          value: "非常不满意",
        },
      ],
    };
  },
  model: {
    prop: "showDialog",
    event: "change",
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit("change", false);
        }
      },
    },
    showDialog: {
      handler(v) {
        // if (v) {
        //   this.dialogVisible = true;
        //   this.$refs.form?.resetFields();
        // } else {
        //   this.dialogVisible = false;
        // }
        if (v) {
          this.form = this.editDetail;
          this.dialogVisible = true;
        }
      },
    },
  },
  mounted() {},
  methods: {
    reset() {
      this.clearClose();
    },
    //检查其他费率是否等于税后费率
    checkOtherBrokerage() {
      const otherRateKeys = [
        "serviceReferralFee",
        "deptReserveFee",
        "companyReserveFee",
        "assessmentServiceFee",
        "commissionFee",
      ];
      let otherSum = 0;
      otherRateKeys.forEach((key) => {
        otherSum += Number(this.form[key] || 0);
      });
      if (this.form.afterTaxRate != otherSum) {
        this.$message.error("其他费率之和不等于税后费率");
        return false;
      }
      return true;
    },
    confirm() {
      if (!this.checkOtherBrokerage()) return;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          adjustKpiRuleFee(this.form).then((res) => {
            this.$emit("changeList");
            this.dialogVisible = false;
          });
        }
      });
    },
    clearClose() {
      // this.form = Object.assign(
      //   {},
      //   {
      //     agentKick: "",
      //     brokerage: "",
      //     otherBrokerage: "",
      //   }
      // );
      this.clearForm();
      this.dialogVisible = false;
    },
    clearForm() {
      let keys = Object.keys(this.form);
      keys.forEach((key) => {
        if (this.form[key] instanceof Array) {
          this.form[key] = [];
        } else if (this.form[key] instanceof Object) {
          Object.keys(this.form[key]).forEach((key2) => {
            this.form[key][key2] = "";
          });
        } else {
          this.form[key] = "";
        }
      });
    },
    // onSelected(e) {
    //   console.log(e.area.value);
    //   this.form.dangerProvince = e.province.value;
    //   this.form.dangerCity = e.city.value;
    //   this.form.dangerCounty = e.area.value;
    //   this.form.riskLocations =
    //     e.province.value + "-" + e.city.value + "-" + e.area.value;
    // },
    input_num(e, tiem, key, max, decimal = 2) {
      inputNum(e, tiem, key, max, decimal);
    },
    // handleRemove(file, t1, t2) {
    //   let ind = this.form[t1].findIndex((val) => val.url == file.url);
    //   if (this.form[t1][ind].id) {
    //     this.form.projectRiskFileIds.push(this.form[t1][ind].id);
    //   }
    //   this.form[t1].splice(ind, 1);
    //   !this.form[t1] || !this.form[t1].length ? (this.form[t2] = "") : "";
    // },
    // async beforeAvatarUpload(file, type, type2) {
    //   this.$refs.uploadaaa.clearFiles();
    //   this.form[type2] = "22222222222222222";
    //   let fileSize = file.size / 1024 / 1024;
    //   if (fileSize > 5) {
    //     this.$message({
    //       message: `上传的文件不能大于${5}MB`,
    //       type: "warning",
    //     });
    //     return;
    //   }
    //   const url = await OSSDirectPass(file);
    //   this.form[type]
    //     ? this.form[type].push({
    //         attName: file.name,
    //         attType: file.name.split(".")[1],
    //         attSize: fileSize + "M",
    //         url,
    //         attUrl: url,
    //       })
    //     : (this.form[type] = [
    //         {
    //           attName: file.name,
    //           attType: file.name.split(".")[1],
    //           attSize: fileSize + "M",
    //           url,
    //           attUrl: url,
    //         },
    //       ]);
    //   this.$forceUpdate();
    // },
    // initType() {
    //   // programmeList
    //   performanceInsuredPage({
    //     likeKey: "",
    //     pageIndex: 1,
    //     pageSize: 9999,
    //   }).then((res) => {
    //     this.customerList = res.data.records.map((val) => {
    //       return {
    //         dictValue: val.playersName,
    //         dictKey: val.playerId,
    //         cardNo: val.cardNo,
    //       };
    //     });
    //   });
    //   performancePlanPage({
    //     likeKey: "",
    //     pageIndex: 1,
    //     pageSize: 9999,
    //   }).then((res) => {
    //     this.programmeList = res.data.records.map((val) => {
    //       return {
    //         dictValue: val,
    //         dictKey: val,
    //       };
    //     });
    //   });
    // },
    handleChangeSalesman() {
      this.$refs.form.validateField("riskPersonnel");
    },
    handleSelectChange(e) {
      let obj = this.customerList.find((val) => val.dictKey == e);
      this.form.customerName = obj.dictValue;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table {
  margin: unset !important;
}
::v-deep .el-table__header-wrapper {
  border-radius: unset !important;
}
</style>
