<!--
 * @Author: zhuchaowei
 * @Date: 2024-05-24 11:50:44
 * @email: 952698119@qq.com
 * @LastEditors: zhuchaowei
 * @LastEditTime: 2024-05-29 10:09:44
 * @Description: 
-->
<template>
  <div class="kpi-dz">
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
      destroy-on-close
      width="30%"
    >
      <div>
        <div class="px-box">
          1.点击下载新的
          <el-button type="text" @click="templateFun"
          >表格模板</el-button
        >
        </div>
        <el-upload
          class="upload"
          drag
          multiple
          action="#" accept=".xls,.xlsx"
          :limit="1"
          :http-request="() => {}"
          :on-change="uploadChange"
        >
          <div style="text-align: left;padding-left: 24px;font-size: 14px;color: #333333;margin-top:10px">2.上传表格（支持格式：xls、xlsx）</div>
          <i class="el-icon-upload"></i>
          <div class="tips">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
        <el-button @click="submitForm" type="primary">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "KpiImportDialog",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    templateFun: {
      type: Function,
      default: () => {
        console.log("没有模板方法");
      },
    },
    title: {
      type: String,
      default: "导入文件",
    },
  },
  data() {
    return {
      dialogVisible: false,
      dataForm: { file: "" },
    };
  },
  watch: {
    show(n) {
      this.dialogVisible = n;
    },
    dialogVisible(n) {
      if (!n) {
        this.$emit("update:show", false);
      }
    },
  },
  methods: {
    clearForm() {
      let keys = Object.keys(this.dataForm);
      keys.forEach((key) => {
        if (this.dataForm[key] instanceof Array) {
          this.dataForm[key] = [];
        } else if (this.dataForm[key] instanceof Object) {
          Object.keys(this.dataForm[key]).forEach((key2) => {
            this.dataForm[key][key2] = "";
          });
        } else {
          this.dataForm[key] = "";
        }
      });
    },
    handleClose(done) {
      this.clearForm();
      done();
    },
    uploadChange(file, fileList) {
      this.dataForm.file = file.raw;
    },
    submitForm() {
      this.$emit("submit", this.dataForm);
      this.clearForm();
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.kpi-dz {
  ::v-deep .el-dialog {
    border-radius: 8px;
    .el-dialog__header {
      position: relative;
      padding: 0;
      padding-left: 24px;
      height: 40px;
      line-height: 40px;
      background: #4278C9;
      border-radius: 8px 8px 0px 0px;
      .el-dialog__title {
        font-weight: bold;
        font-size: 18px;
        color: #FFFFFF;
      }
      .el-dialog__headerbtn {
        top: 50%;
        transform: translateY(-50%);
        .el-dialog__close {
          font-weight: bold;
          font-size: 18px;
          color: #FFFFFF;
        }
      }
    }
  }
  ::v-deep .el-dialog__body {
    padding: 24px 32px;
    border-top: 1px solid rgba(120, 120, 120, 0.3);
    border-bottom: 1px solid rgba(120, 120, 120, 0.3);
  }
  .px-box {
    height: 60px;
    line-height: 60px;
    font-weight: 400;
    font-size: 14px;
    color: #333;
    padding-left: 24px;
    margin-bottom: 12px;
    background: #F3F8FF;
    border-radius: 8px 8px 8px 8px;
    border: 1px dashed #D1E3FE;
    .el-button--text {
      font-size: 14px;
    }
  }
}
.upload {
  width: 100%;
  ::v-deep .el-upload {
    width: 100%;
    .el-upload-dragger {
      width: 100%;
      background: #f3f8ff !important;
    }
  }
}
.el-icon-upload {
  color: #4278c9;
}
.tips {
  font-weight: 400;
  font-size: 18px;
  color: #333333;
}
.flex-center {
  display: flex;
  //justify-content: center;
  align-items: center;
}
.download-btn {
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #0080ff;
  color: #0080ff;
  margin-bottom: 8px;
}
</style>
